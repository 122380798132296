import Dune from "@/assets/images/other_book/Dune.jpg";
import Good_Vibes_Good_Life from "@/assets/images/other_book/Good Vibes Good Life .jpg";
import How_Highly_Effective_People_Speak_How_High_Performers_Use_Psychology from "@/assets/images/other_book/How_Highly_Effective_People_Speak_How_High_Performers_Use_Psychology.jpg";
import How_Leaders_Can_Inspire_Accountability_Three_Habits_That_Make_or from "@/assets/images/other_book/How_Leaders_Can_Inspire_Accountability_Three_Habits_That_Make_or.jpg";
import It_Ends_with_Us from "@/assets/images/other_book/It Ends with Us.jpg";
import It_Starts_with_Us from "@/assets/images/other_book/It Starts with Us.jpg";
import Start_with_Why from "@/assets/images/other_book/Start with Why.jpg";
import The_Power_of_One_More_The_Ultimate_Guide_to_Happiness_and_Success from "@/assets/images/other_book/The_Power_of_One_More_The_Ultimate_Guide_to_Happiness_and_Success.jpg";
import Think_Again_The_Power_of_Knowing_What_You_Dont_Know from "@/assets/images/other_book/Think Again The Power of Knowing What You Don't Know.jpg";
import Think_and_Grow_RichG from "@/assets/images/other_book/Think and Grow RichG&D Media.jpg";


export const Other = [
  {
    image: Dune,
    title: "Dune",
    price: 8,
  },

  {
    image: Good_Vibes_Good_Life,
    title: "Good Vibes, Good Life",
    price: 7,
  },
  {
    image: How_Highly_Effective_People_Speak_How_High_Performers_Use_Psychology,
    title:
      "How Highly Effective People Speak: How High Performers Use Psychology",
    price: 7,
  },
  {
    image: How_Leaders_Can_Inspire_Accountability_Three_Habits_That_Make_or,
    title:
      "How Leaders Can Inspire Accountability: Three Habits That Make or Break",
    price: 7,
  },
  {
    image: It_Ends_with_Us,
    title:
      "It Ends with Us",
    price: 7,
  },
  {
    image: It_Starts_with_Us,
    title:
      "It Starts with Us",
    price: 7,
  },
  {
    image: Start_with_Why,
    title:
      "Start with Why",
    price: 7,
  },
  {
    image: The_Power_of_One_More_The_Ultimate_Guide_to_Happiness_and_Success,
    title:
      "The Power of One More: The Ultimate Guide to Happiness and Success",
    price: 7,
  },
  {
    image: Think_Again_The_Power_of_Knowing_What_You_Dont_Know,
    title:
      "Think Again: The Power of Knowing What You Don't Know",
    price: 7,
  },
  {
    image: Think_and_Grow_RichG,
    title:
      "Think and Grow Rich",
    price: 7,
  },
];
