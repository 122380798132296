import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div>
      <button
        onClick={() => navigate(-1)}
        className="fixed bottom-5 left-5 z-50 bg-blue-500 text-white p-2 rounded-full"
      >
        <FaArrowLeft />
      </button>
    </div>
  );
};

export default BackButton;
