import { Routes, Route, Outlet } from "react-router-dom";
import SchoolFreeBook from "../pages/sub-pages/free-book/SchoolFreeBook";
import MainFreeBook from "../pages/sub-pages/free-book/MainFreeBook";
import LeafBook from "../pages/sub-pages/free-book/LeafBook";
import LeafView from "../components/LeafView";
import GeneralBook from "../pages/sub-pages/free-book/GeneralBook";
import PdfReader from "../components/PdfReader";
import FreeComic from "@/pages/sub-pages/free-book/FreeComic";


const FreeBook = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainFreeBook />} />
        <Route path="school-free-book" element={<SchoolFreeBook />} />
        <Route path="leaf-book" element={<LeafBook />} />
        <Route path="leaf-book/:id" element={<LeafView />} />
        <Route path="general-book" element={<GeneralBook />} />
        <Route path="general-book/:pdf" element={<PdfReader />} />
        <Route path="comic-book" element={<FreeComic />} />
        <Route path="comic-book/:pdf" element={<PdfReader />} />
        <Route path="school-book" element={<SchoolFreeBook />} />
        <Route path="school-book/:pdf" element={<PdfReader />} />
      </Routes>
      <Outlet />
    </>
  );
};

export default FreeBook;
