import { Link } from "react-router-dom";



const NotFound = () => {
  return (
    <div className="min-h-screen flex items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            404: Page Not Found
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Sorry, the page you are looking for does not exist.
          </p>
          <div className="mt-8 flex justify-center">
            <Link
              to="/"
              className="text-center bg-accent border border-transparent rounded-md py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-accent-dark"
            >
              Go back to Home
            </Link>
          </div>
          {/* create a go back a tag button back to '/' */}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
