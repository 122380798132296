import Heaven_Official_Blessing_Vol_1 from "@/assets/images/novel/Heaven_Official_Blessing_Vol_1.jpg";
import Heaven_Official_Blessing_Vol_2 from "@/assets/images/novel/Heaven_Official_Blessing_Vol_2.jpg";
import Heaven_Official_Blessing_Vol_3 from "@/assets/images/novel/Heaven_Official_Blessing_Vol_3.jpg";
import Heaven_Official_Blessing_Vol_4 from "@/assets/images/novel/Heaven_Official_Blessing_Vol_4.jpg";
import Heaven_Official_Blessing_Vol_5 from "@/assets/images/novel/Heaven_Official_Blessing_Vol_5.jpg";
import Heaven_Official_Blessing_Vol_6 from "@/assets/images/novel/Heaven_Official_Blessing_Vol_6.jpg";
import Heaven_Official_Blessing_Vol_7 from "@/assets/images/novel/Heaven_Official_Blessing_Vol_7.jpg";
import Heaven_Official_Blessing_Vol_8 from "@/assets/images/novel/Heaven_Official_Blessing_Vol_8.jpg";

export const Novel = [
  {
    image: Heaven_Official_Blessing_Vol_1,
    title: "Heaven Official's Blessing Vol 1",
    price: 7,
  },
  {
    image: Heaven_Official_Blessing_Vol_2,
    title: "Heaven Official's Blessing Vol 2",
    price: 7,
  },
  {
    image: Heaven_Official_Blessing_Vol_3,
    title: "Heaven Official's Blessing Vol 3",
    price: 7,
  },
  {
    image: Heaven_Official_Blessing_Vol_4,
    title: "Heaven Official's Blessing Vol 4",
    price: 7,
  },
  {
    image: Heaven_Official_Blessing_Vol_5,
    title: "Heaven Official's Blessing Vol 5",
    price: 7,
  },
  {
    image: Heaven_Official_Blessing_Vol_6,
    title: "Heaven Official's Blessing Vol 6",
    price: 7,
  },
  {
    image: Heaven_Official_Blessing_Vol_7,
    title: "Heaven Official's Blessing Vol 7",
    price: 7,
  },
  {
    image: Heaven_Official_Blessing_Vol_8,
    title: "Heaven Official's Blessing Vol 8",
    price: 7,
  },
];
