import { useEffect } from "react";
import Navbar from "../components/Navbar";
import useGetTopAnimes, { AnimeItem } from "../hook/useGetTopAnimes";

const AdimeQuiz = () => {
  const { results, isDone } = useGetTopAnimes(1); // Fetch 1 page of animes

  useEffect(() => {
    if (isDone) {
      console.log(results); // Log the results when done
    }
  }, [isDone, results]);

  return (
    <>
      <Navbar />

      <div>
        {results.map((anime: AnimeItem) => (
          <div key={anime.id}>
            <h2 className="text-green-400">{anime.title}</h2>
            <ul>
              {anime.characters.map((character) => (
                <li className="text-red-400" key={character.id}>
                  {character.name}{" "}
                  <img className="w-5" src={character.image} alt="" />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

export default AdimeQuiz;
