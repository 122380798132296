import Navbar from "../../../components/Navbar";
import { Link } from "react-router-dom";
import LeafBooks from "../../../configs/leaf-book-data.json";
import Footer from "@/components/Footer";
import BackButton from "@/components/BackButton";


const LeafBook = () => {
  return (
    <div>

      <BackButton />
      <Navbar />

      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6  gap-3 py-20 px-7">
        {LeafBooks.map((book) => {
          return (
            <Link to={`/free-book/leaf-book/${book.id}`} key={book.id}>
              <div className="bg-white rounded-lg shadow-lg p-3 text-center">
                <img
                  src={`https://archive.org/services/img/${book.id}`}
                  alt={book.title}
                  className="w-full h-40 object-contain rounded-lg"
                />
                <div className="pt-3">
                  <h3 className="text-sm  ">
                    {book.title.substring(0, 20)}
                    {book.title.length > 20 ? "..." : ""}
                  </h3>
                </div>
              </div>
            </Link>
          );
        })}
      </div>

      <Footer />
    </div>
  );
};

export default LeafBook;
