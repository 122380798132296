const StoreBook = ({ jsonData, title }: { jsonData: any; title: string }) => {
  return (
    <section>
      <h1 className="pt-10 text-3xl text-center">{title}</h1>
      <hr className="h-px my-8 bg-gray-400 border-0" />

      <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-6 py-15 px-7">
        {jsonData.map((book: any) => {
          return (
            <div
              className="p-3 text-center bg-white rounded-lg shadow-lg"
              key={book.title}
            >
              <img
                // loading="lazy"
                src={book.image}
                alt={book.title}
                className="object-contain w-full h-40 rounded-lg"
              />
              <div className="pt-3">
                {book.isCommingSoon && (
                  <p className="text-red-400">Comming Soon</p>
                )}
                <p className="text-red-400">{`$${book.price}`}</p>
                <h3 className="text-sm ">{book.title}</h3>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default StoreBook;
