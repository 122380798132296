import Navbar from "@/components/Navbar";
import { Link } from "react-router-dom";
import icon1 from "@/assets/icons/torah.png";
import icon2 from "@/assets/icons/book.png";
import icon3 from "@/assets/icons/marvel.png";
import icon4 from "@/assets/icons/icons8-school-96.png";
import BackButton from "@/components/BackButton";
// import Footer from "@/components/Footer";

const MainFreeBook = () => {
  return (
    <div>
      <BackButton />
      <Navbar />

      <div className="grid grid-cols-2 gap-3 pt-20 sm:grid-cols-3 md:grid-cols-6 px-7">
        <Link to="/free-book/leaf-book">
          <div className="p-3 text-center bg-white rounded-lg shadow-lg">
            <img
              src={icon1}
              alt="Free Book"
              className="object-contain w-full h-40 rounded-lg"
            />
            <div className="pt-3">
              <p>សាស្ត្រា​ស្លឹក​រឹត</p>
            </div>
          </div>
        </Link>

        <Link to="/free-book/general-book">
          <div className="p-3 text-center bg-white rounded-lg shadow-lg">
            <img
              src={icon2}
              alt="General Book"
              className="object-contain w-full h-40 rounded-lg"
            />
            <div className="pt-3">
              <p>សៀវភៅទូទៅ</p>
            </div>
          </div>
        </Link>

        <Link to="/free-book/comic-book">
          <div className="p-3 text-center bg-white rounded-lg shadow-lg">
            <img
              src={icon3}
              alt="General Book"
              className="object-contain w-full h-40 rounded-lg"
            />
            <div className="pt-3">
              <p>Marvel & DC</p>
            </div>
          </div>
        </Link>

        <Link to="/free-book/school-book">
          <div className="p-3 text-center bg-white rounded-lg shadow-lg">
            <img
              src={icon4}
              alt="General Book"
              className="object-contain w-full h-40 rounded-lg"
            />
            <div className="pt-3">
              <p>សៀវភៅរៀន</p>
            </div>
          </div>
        </Link>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default MainFreeBook;
