// image : https://archive.org/services/img/bdrc-W1FEMC011361

import { useParams } from "react-router-dom";
import { useState } from "react";
import BackButton from "@/components/BackButton";


const LeafView = () => {
  let { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="h-screen">
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: "24px",
            // fontWeight: "bold",
          }}
        >
          Loading...
        </div>
      )}
      <BackButton />
      <iframe
        src={`https://archive.org/embed/${id}`}
        width="100%"
        height="100%"
        frameBorder="0"
        data-dashlane-frameid="540"
        onLoad={handleIframeLoad}
      ></iframe>
    </div>
  );
};

export default LeafView;
