import Navbar from "@/components/Navbar";
import StoreBook from "@/components/StoreBook";
import { Mangas } from "@/configs/Mangas";
import { Manhwa } from "@/configs/Manhwa";
import { MangasKhmer } from "@/configs/MangasKhmer";
import { Yaio } from "@/configs/Yaio";
import { Novel } from "@/configs/Novel";
import { Other } from "@/configs/Other";
import { FaArrowUp } from "react-icons/fa";
import { useRef } from "react";
import Footer from "@/components/Footer";

const Home = () => {
  const topRef = useRef<HTMLDivElement | null>(null);
  function topRefScroll() {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const mangaRef = useRef<HTMLDivElement | null>(null);
  function mangaRefScroll() {
    mangaRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const mangaKhmerRef = useRef<HTMLDivElement | null>(null);
  function mangaKhmerRefScroll() {
    mangaKhmerRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const manhwaRef = useRef<HTMLDivElement | null>(null);
  function manhwaRefScroll() {
    manhwaRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const yaioRef = useRef<HTMLDivElement | null>(null);
  function yaioRefScroll() {
    yaioRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const novelRef = useRef<HTMLDivElement | null>(null);
  function novelRefScroll() {
    novelRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const otherRef = useRef<HTMLDivElement | null>(null);
  function otherRefScroll() {
    otherRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <div ref={topRef}></div>
      <button
        onClick={topRefScroll}
        className="fixed bottom-5 right-5 z-50 bg-blue-500 text-white p-2 rounded-full"
      >
        <FaArrowUp />
      </button>
      <Navbar />
      {/* create row of category button for user click it scroll to that section */}
      <div className=" top-0 z-10 ">
        <div className="flex justify-center gap-5 py-5 flex-wrap">
          <button
            onClick={() => {
              mangaRefScroll();
            }}
            className="bg-accent text-white py-1 px-3 rounded-lg"
          >
            Manga
          </button>
          <button
            onClick={() => {
              mangaKhmerRefScroll();
            }}
            className="bg-accent text-white py-1 px-3 rounded-lg"
          >
            Manga Khmer
          </button>

          <button
            onClick={() => {
              manhwaRefScroll();
            }}
            className="bg-accent text-white py-1 px-3 rounded-lg"
          >
            Manhwa
          </button>

          <button
            onClick={() => {
              yaioRefScroll();
            }}
            className="bg-accent text-white py-1 px-3 rounded-lg"
          >
            BL Manga (yaoi)
          </button>

          <button
            onClick={() => {
              novelRefScroll();
            }}
            className="bg-accent text-white py-1 px-3 rounded-lg"
          >
            Novel
          </button>

          <button
            onClick={() => {
              otherRefScroll();
            }}
            className="bg-accent text-white py-1 px-3 rounded-lg"
          >
            Other
          </button>
        </div>
      </div>

      <div ref={mangaKhmerRef}>
        <StoreBook jsonData={MangasKhmer} title="Manga Khmer" />
      </div>

      <div ref={manhwaRef}>
        <StoreBook jsonData={Manhwa} title="Manhwa" />
      </div>

      <div ref={mangaRef}>
        <StoreBook jsonData={Mangas} title="Manga" />
      </div>

      <div ref={yaioRef}>
        <StoreBook jsonData={Yaio} title="BL Manga (yaoi)" />
      </div>

      <div ref={novelRef}>
        <StoreBook jsonData={Novel} title="Novel" />
      </div>

      <div ref={otherRef}>
        <StoreBook jsonData={Other} title="Other" />
      </div>

      <Footer />
    </>
  );
};

export default Home;
