import Navbar from "@/components/Navbar";
import { Link } from "react-router-dom";
import SchoolBooks from "@/configs/school-book.json";
import Footer from "@/components/Footer";
import BackButton from "@/components/BackButton";

const SchoolFreeBook = () => {
 return (
   <div>
     <BackButton />
     <Navbar />

     <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6  gap-3 py-20 px-7">
       {SchoolBooks.map((book) => {
         return (
           <Link to={`/free-book/general-book/${book.id}`} key={book.id}>
             <div className="bg-white rounded-lg shadow-lg p-3 text-center">
               <img
                 src={`https://bonnary.github.io/free-book/images/${book.id}.jpg`}
                 alt={book.title}
                 className="w-full h-40 object-contain rounded-lg"
               />
               <div className="pt-3">
                 <h3 className="text-sm  ">
                   {book.title.substring(0, 15)}
                   {book.title.length > 15 ? "..." : ""}
                 </h3>
               </div>
             </div>
           </Link>
         );
       })}
     </div>

     <Footer />
   </div>
 );
};

export default SchoolFreeBook;
