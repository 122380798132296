import { FaPhoneAlt, FaFacebook, FaTelegram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="w-full bg-accent flex flex-col items-center mt-20">
      <img className="footer-logo w-12 my-4" src="logo.png" alt="" />
      <p className="text-white mb-4">សម្រាប់ព័ត៌មានបន្ថែម</p>
      {/* <div className="element-group">
        <p className="flex items-center text-white">
          <i className="fas fa-home mr-2"></i>ភូមិចាក់ជ្រូក, សង្កាត់សំរោងក្រោម,
          ខ័ណ្ឌពោធិ៍សែនជ័យ, រាជធានីភ្នំពេញ
        </p>
      </div> */}
      <div className="element-group">
        <p className="text-white flex items-center gap-5">
          <FaPhoneAlt />
          096 687 9704 - 096 583 8834
        </p>
      </div>
      {/* <div className="element-group">
        <i className="fas fa-envelope text-white mr-2"></i>
        <p className="text-white">sodafertilizer@gmail.com</p>
      </div> */}
      <div className="icon-container flex gap-5 py-5">
        <a
          target="_blank"
          href="https://www.facebook.com/profile.php?id=61551368796728"
        >
          <FaFacebook size={24} className="text-white" />
        </a>
        {/* <a target="_blank" href="https://m.me/sodafertilizer">
          <img
            className="w-8 mx-1 hover:border-gray-400 hover:border-solid hover:border rounded-full transition duration-300"
            src="/images/icons/messenger.svg"
            alt=""
          />
        </a> */}
        <a target="_blank" href="https://t.me/comic_haven">
          <FaTelegram size={24} className="text-white" />
        </a>
        {/* <a
          target="_blank"
          href="https://www.tiktok.com/@sodafertilizer?lang=en"
        >
          <img
            className="w-8 mx-1 hover:border-gray-400 hover:border-solid hover:border rounded-full transition duration-300"
            src="/images/icons/tiktok.svg"
            alt=""
          />
        </a> */}
        {/* <a
          target="_blank"
          href="https://www.youtube.com/channel/UCpadj0nbbmgVSyX5opSXHug"
        >
          <img
            className="w-8 mx-1 hover:border-gray-400 hover:border-solid hover:border rounded-full transition duration-300"
            src="/images/icons/youtube.svg"
            alt=""
          />
        </a> */}
      </div>
    </footer>
  );
};

export default Footer;
