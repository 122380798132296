import Aitsu_No_Daihonmei from "@/assets/images/manga/bl/Aitsu No Daihonmei.jpg";
import Ganbare_Nakamura_Kun from "@/assets/images/manga/bl/Ganbare Nakamura-Kun.jpg";
import Given from "@/assets/images/manga/bl/Given.jpg";
import Mitsumei from "@/assets/images/manga/bl/Mitsumei.jpg";
import Seven_Days from "@/assets/images/manga/bl/Seven Days.jpg";


export const Yaio = [
  {
    image: Aitsu_No_Daihonmei,
    title: "Aitsu No Daihonmei",
    price: 6,
  },
  {
    image: Ganbare_Nakamura_Kun,
    title: "Ganbare Nakamura-Kun",
    price: 6,
  },
  {
    image: Given,
    title: "Given",
    price: 6,
  },
  {
    image: Mitsumei,
    title: "Mitsumei",
    price: 6,
  },
  {
    image: Seven_Days,
    title: "Seven Days",
    price: 7,
  }
];
