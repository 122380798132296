import cmEOjmS from "@/assets/images/manga/my dress up darling part 1.jpg";
import Demon from "@/assets/images/manga/Demon Slayer 1.jpg";
import JujutsuKaisen from "@/assets/images/manga/Jujutsu Kaisen part 1 .jpg";


export const MangasKhmer = [
  {
    image: cmEOjmS,
    title: "My Dress Up Darling Khmer",
    price: 6,
  },
  {
    image: Demon,
    title: "Demon Slayer Khmer",
    price: 6,
  },
  {
    image: JujutsuKaisen,
    title: "Jujutsu Kaisen Khmer",
    price: 6,
  },
];
