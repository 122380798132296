import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "@/pages/Home";
import NotFound from "@/pages/NoPage";
import AnimeQuiz from "@/pages/AnimeQuiz";
import FreeBook from "@/routes/FreeBook";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="game" element={<AnimeQuiz />} />
        <Route path="free-book/*" element={<FreeBook />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
