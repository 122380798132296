import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";


const Navbar = () => {
  
  return (
    <div className="py-5 bg-accent">
      <div className="flex justify-between">
        <div className="flex items-center px-5">
          <Link to="/">
            <img src={Logo} alt="" className="w-16" />
          </Link>
        </div>

        <div className="flex items-center px-5 ">
          <Link to="/free-book">
            <button className="px-3 py-1 bg-white rounded-3xl">
              Free Books
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
