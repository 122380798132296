import SoloLeveling1 from "@/assets/images/manhwa/Solo_leveling_Volume_1.jpg";
import SoloLeveling2 from "@/assets/images/manhwa/Solo_leveling_Volume_2.jpg";
import SoloLeveling3 from "@/assets/images/manhwa/Solo_leveling_Volume_3.jpg";
import SoloLeveling4 from "@/assets/images/manhwa/Solo_leveling_Volume_4.jpg";
import SoloLeveling5 from "@/assets/images/manhwa/Solo_leveling_Volume_5.jpg";
import SoloLeveling6 from "@/assets/images/manhwa/Solo_leveling_Volume_6.jpg";
import SoloLeveling7 from "@/assets/images/manhwa/Solo_leveling_Volume_7.jpg";
import SoloLeveling8 from "@/assets/images/manhwa/Solo_leveling_Volume_8.jpg";
export const Manhwa = [
  {
    image: SoloLeveling1,
    title: "Solo leveling Volume 1",
    price: 8,
    // isCommingSoon: true,
  },
  {
    image: SoloLeveling2,
    title: "Solo leveling Volume 2",
    price: 8,
    // isCommingSoon: true,
  },
  {
    image: SoloLeveling3,
    title: "Solo leveling Volume 3",
    price: 8,
    // isCommingSoon: true,
  },
  {
    image: SoloLeveling4,
    title: "Solo leveling Volume 4",
    price: 8,
    // isCommingSoon: true,
  },
  {
    image: SoloLeveling5,
    title: "Solo leveling Volume 5",
    price: 8,
    // isCommingSoon: true,
  },
  {
    image: SoloLeveling6,
    title: "Solo leveling Volume 6",
    price: 8,
    // isCommingSoon: true,
  },
  {
    image: SoloLeveling7,
    title: "Solo leveling Volume 7",
    price: 8,
    // isCommingSoon: true,
  },
  {
    image: SoloLeveling8,
    title: "Solo leveling Volume 8",
    price: 8,
    // isCommingSoon: true,
  },
];
